<template>
	<div class="privacy-settings">
		<div>
			<Navbar />
		</div>
		<div class="privacy-settings__header" :style="{ 'background-color': primaryColor }">
			<h1 class="privacy-settings__header-title">{{ $t('privacysetting.title') }}</h1>
		</div>
		<div class="privacy-settings__body">
			<v-card max-width="1000" flat class="mx-auto fill-height py-10">
				<v-row>
					<v-checkbox v-model="receiveInformation" @change="changeReceiveInformationHandler" :true-value="1" :false-value="0">
						<template v-slot:label>
							<h3>{{ $t('privacysetting.checkboxAcceptReceiveInformation') }}</h3>
						</template>
					</v-checkbox>
				</v-row>
				<v-row v-if="receiveInformation">
					<v-col cols="12" :md="6" class="px-2">
						<v-data-table :items-per-page="9999" sortBy="egtrname" dense hide-default-footer :headers="headers" :items="subscribedGroups">
							<template v-slot:top>
								<v-toolbar flat>
									<v-toolbar-title>{{ $t('privacysetting.myevents') }}</v-toolbar-title>
									<v-spacer></v-spacer>
								</v-toolbar>
							</template>
							<template v-slot:footer>
								<div class="d-flex justify-end align center mt-2">
									<v-btn outlined class="my-2 mr-2" @click="aceptAllEvents"> {{ $t('privacysetting.acceptAllEvents') }} </v-btn>
									<v-btn outlined class="my-2" :color="primaryColor" @click="saveChanges"
										>{{ $t('privacysetting.saveChanges') }}
									</v-btn>
								</div>
							</template>
							<template v-slot:item.relareceiveeventmessages="{ item }">
								<v-list-item-action>
									<span>{{
										`${item.relareceiveeventmessages ? $t('privacysetting.accept') : $t('privacysetting.iDoNotAccept')}`
									}}</span>
								</v-list-item-action>
								<v-list-item-action>
									<v-switch
										v-model="item.relareceiveeventmessages"
										:true-value="1"
										:false-value="0"
										:color="primaryColor"
									></v-switch>
								</v-list-item-action>
							</template>
						</v-data-table>
					</v-col>
					<v-col cols="12" :md="6" class="px-2">
						<available-events-table :unsubscribedGroups="unsubscribedGroups" @addEvent="addEvent" />
					</v-col>
				</v-row>
			</v-card>
		</div>
		<footer class="privacy-settings__footer">
			<Footer />
		</footer>
		<v-dialog v-model="dialog" width="500">
			<v-card>
				<v-card-title class="text-h5 grey lighten-2"> {{ $t('privacysetting.privacyPolicy') }} </v-card-title>

				<v-card-text class="pt-2">
					{{ receiveInformation ? $t('privacysetting.acceptPrivacyPolicy') : $t('privacysetting.cancelPrivacyPolicy') }}
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-btn
						color="primary"
						text
						@click="
							() => {
								dialog = false;
								receiveInformation = receiveInformation === 1 ? 0 : 1;
							}
						"
					>
						{{ $t('privacysetting.iDoNotAccept') }}
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="handleUpdateReceiveInformation"> {{ $t('privacysetting.accept') }} </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-overlay :value="isLoading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</div>
</template>

<script>
import Navbar from '@/components/navbar/Navbar.vue';
import Footer from '@/components/footer/Footer.vue';
import { getUserEventRelationship, saveUserEventRelationShip, getUserRelationShip, updateUserReceiveInformation, saveLogsAccess, receiveDateNow } from '@/api/common.js';
import { mapGetters } from 'vuex';
import AvailableEventsTable from '../../components/privacySetting/AvailableEventsTable.vue';

export default {
	name: 'PrivacySetting',
	title() {
		return `${this.$t('account.title')} | ${this.$store.state.eventInfo.evtrname}`;
	},
	components: {
		Navbar,
		Footer,
		'available-events-table': AvailableEventsTable
	},
	data() {
		return {
			isLoading: false,
			receiveInformation: undefined,
			dialog: false,
			subscribedGroups: [],
			unsubscribedGroups: [],
			headers: [
				{
					text: this.$t('privacysetting.name'),
					align: 'start',
					sortable: true,
					value: 'egtrname'
				},
				{ text: this.$t('privacysetting.receiveInformation'), align: 'end', value: 'relareceiveeventmessages', sortable: false }
			]
		};
	},
	mounted() {
		this.getUserData();

		const user = JSON.parse(localStorage.getItem('pui9_session'));

		saveLogsAccess({
			loacfecha: receiveDateNow(),
			loacusr: user ? user.usr : 'anonymous',
			loacfuncionalidad: "Ajustes de privacidad",
			loaceventgroupid: this.$store.state.eventInfo.eveneventgroupid,
			loacurl: this.$route.path,
			loacip: user ? user.lastLoginIp : '0.0.0.0',
			loacenvironment: "CATÁLOGO",
			loacproductserviceid: 0,
			loacexhibitorid: 0
		});
	},
	computed: {
		...mapGetters(['getSession']),
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	},
	methods: {
		handleUpdateReceiveInformation() {
			this.dialog = false;
			this.isLoading = true;
			const body = {
				usr: this.getSession.usr,
				receiveInformation: this.receiveInformation
			};
			updateUserReceiveInformation(body)
				.then((res) => (this.receiveInformation === 1 ? this.getUserEventData() : Promise.resolve(res)))
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		changeReceiveInformationHandler(val) {
			if (val === 0) {
				this.dialog = true;
			}
			if (val === 1) {
				this.dialog = true;
			}
		},
		getUserData() {
			const params = {
				usr: this.getSession.usr
			};
			this.isLoading = true;
			getUserRelationShip({ params })
				.then((res) => {
					this.receiveInformation = res.data.usfvreceiveinformation;
					if (res.data.usfvreceiveinformation === 1) {
						this.getUserEventData();
					}
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		saveChanges() {
			const body = {
				usr: this.getSession.usr,
				updateSubscribedEvent: this.subscribedGroups
					.filter((e) => !e.newSubscribedEvent)
					.map((e) => ({
						relareceiveeventmessages: e.relareceiveeventmessages,
						usr: this.getSession.usr,
						evgrid: e.evgrid
					})),
				newSubscribedEvent: this.subscribedGroups
					.filter((e) => e.newSubscribedEvent)
					.map((e) => ({
						relareceiveeventmessages: e.relareceiveeventmessages,
						usr: this.getSession.usr,
						evgrid: e.evgrid
					}))
			};
			this.isLoading = true;

			saveUserEventRelationShip(body)
				.then(this.getUserEventData)
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		aceptAllEvents() {
			this.subscribedGroups = this.subscribedGroups.map((event) => ({ ...event, relareceiveeventmessages: 1 }));
		},
		addEvent(item) {
			this.unsubscribedGroups = this.unsubscribedGroups.filter((event) => event.evgrid !== item.evgrid);
			this.subscribedGroups = [...this.subscribedGroups, item];
		},
		goToHome() {
			this.$store.dispatch('puiRouterGoToUrl', { router: this.$router, url: `/${this.$store.state.eventInfo.evgrurlname}/home` });
		},
		getUserEventData() {
			return getUserEventRelationship({ usr: this.getSession.usr })
				.then((res) => {
					this.subscribedGroups = res.data.subscribedGroups;
					this.unsubscribedGroups = res.data.unsubscribedGroups.map((ev) => ({ ...ev, actions: true }));
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.privacy-settings {
	display: flex;
	flex-direction: column;
	width: 100%;

	&__navbar,
	&__header,
	&__footer {
		flex: none;
	}
}

.privacy-settings__header {
	background-color: #565567;
	height: 15vh;
	display: flex;
	align-items: center;
}

.privacy-settings__header-title {
	font-family: Cabin;
	font-weight: bold;
	font-size: 2rem;
	letter-spacing: 0.0625rem;
	color: #fff;
	padding-left: 10%;
	padding-top: 1.5rem;
}

.privacy-settings__body {
	height: 100%;
}
</style>
