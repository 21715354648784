<template>
	<div>
		<v-data-table
			sortBy="egtrname"
			dense
			:headers="headersUnsubscribedGroups"
			:items="unsubscribedGroups"
			:page.sync="page"
			:items-per-page="itemsPerPage"
			hide-default-footer
			@page-count="pageCount = $event"
		>
			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>{{ $t('privacysetting.availableEvents') }}</v-toolbar-title>
					<v-spacer></v-spacer>
				</v-toolbar>
			</template>

			<template v-slot:item.actions="{ item }">
				<v-tooltip right>
					<template v-slot:activator="{ on, attrs }">
						<v-btn class="mx-2" @click="addEvent(item)" v-bind="attrs" v-on="on" icon><v-icon class="fas fa-plus-square"></v-icon></v-btn>
					</template>
					<span>{{ $t('privacysetting.addMyEvents') }}</span>
				</v-tooltip>
			</template>
		</v-data-table>
		<div>
			<pagination-fv v-model="page" :totalElements="unsubscribedGroups.length" :pageSize="itemsPerPage" />
		</div>
	</div>
</template>

<script>
import PaginationFv from '../common/PaginationFv.vue';
export default {
	components: { 'pagination-fv': PaginationFv },
	props: {
		unsubscribedGroups: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			page: 1,
			itemsPerPage: 10,
			pageCount: 0,
			headersUnsubscribedGroups: [
				{
					text: this.$t('privacysetting.name'),
					align: 'start',
					sortable: true,
					value: 'egtrname'
				},
				{ align: 'end', value: 'actions', text: this.$t('privacysetting.actions'), sortable: false }
			]
		};
	},
	methods: {
		addEvent(item) {
			const newSubscribedEvent = {
				egtrname: item.egtrname,
				evgrid: item.evgrid,
				relareceiveeventmessages: 0,
				newSubscribedEvent: true
			};
			this.$emit('addEvent', newSubscribedEvent);
		}
	}
};
</script>

<style>
</style>
