<template>
	<div class="pagination-catalog__pagination">
		<span class="pagination-catalog__pagination-info">
			{{ currentPage * pageSize - pageSize + 1 }} - {{ currentPage * pageSize }} de {{ totalElements }}</span
		>
		<button @click="goFirst()" class="pagination-catalog__pagination-button"><i class="far fa-arrow-to-left"></i></button>
		<button @click="goPrev()" class="pagination-catalog__pagination-button"><i class="far fa-arrow-left"></i></button>
		<div class="pagination-catalog__pagination-select">
			<v-select v-model="currentPage" :items="totalPages()" outlined append-icon=""></v-select>
		</div>
		<button @click="goNext()" class="pagination-catalog__pagination-button"><i class="far fa-arrow-right"></i></button>
		<button @click="goLast()" class="pagination-catalog__pagination-button"><i class="far fa-arrow-to-right"></i></button>
	</div>
</template>

<script>
export default {
	props: {
		totalElements: {
			type: Number,
			required: true
		},
		value: {
			type: Number,
			required: true
		},
		pageSize: {
			type: Number,
			required: true
		}
	},
	computed: {
		currentPage: {
			get() {
				return this.value;
			},
			set(newValue) {
				this.$emit('input', newValue);
			}
		}
	},
	methods: {
		goFirst() {
			this.$emit('changePage', 1);
		},
		goPrev() {
			const toPage = this.currentPage;
			if (toPage > 1) {
				this.currentPage = toPage - 1;
			}
		},
		goNext() {
			const toPage = this.currentPage;
			if (this.currentPage < Math.ceil(this.totalElements / this.pageSize)) {
				this.currentPage = toPage + 1;
			}
		},
		goLast() {
			const toPage = Math.ceil(this.totalElements / this.pageSize);
			this.currentPage = toPage;
		},
		totalPages() {
			return Array.from({ length: Math.ceil(this.totalElements / this.pageSize) }, (item, index) => (item = index + 1));
		}
	}
};
</script>

<style lang="scss" scoped>
.pagination-catalog__pagination {
	display: flex;
	justify-content: center;
	align-items: baseline;
	padding-top: 1rem;

	&-info {
		margin-right: 0.5rem;
	}

	&-button {
		padding: 0.5rem 0.75rem;
		margin: 0.125rem;
		border-radius: 6px;
		background-color: #f5f7f7;
	}

	&-select {
		width: 4rem;
		margin: 0 0.25rem;
	}
}
</style>
